import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import ServiceHero from '@/components/ServiceHero';

export default function TransitionDigitalePage({ data }) {
  const { t } = useTranslation();

  const contentTransitionDigitale = {
    name: t('digitalTitle'),
    bg: 'bg-greenLighter',
    blobFirstColor: '#D1D4E9',
    blobSecondColor: '#E2E5F9',
    content: t('digitalContent'),
  };
  return (
    <Layout
      title="Services: Transition digitale"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
      className="mt-8"
    >
      <ServiceHero tags={contentTransitionDigitale} img={data.brief} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["services", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    brief: file(relativePath: { eq: "transition.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
